import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Layout from './component/Layout';
// import Top from './Top';
import Works from './Works';
// import About from './About';
// import Contact from './Contact';
// import SendComplete from './component/contact/sendComplete/SendComplete'
import NotFound from './component/notfound/NotFound';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path={`/`} element={<Layout />} />
          {/* <Route index path={`/`} element={<Top />} /> */}
        <Route path={`/works`} element={<Works />} />
        
          {/* TopコンポーネントにHeaderとOutletを設置し、pathに対応する各コンポーネントをHeaderと共に表示する */}
          {/* <Route index element={<Top />} />        
          <Route path={`about`} element={<About />} />
          <Route path={`works`} element={<Works />} />
          <Route path={`contact`} element={<Contact />} /> */}
        <Route path={`/*`} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
