import { useState } from "react";
// import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ToggleButton from "./ToggleButton"
import Navigation from "./Navigation"
import video from "../../img/bike.mp4";
import './Sp-style.css';

const HeaderSp = (props) => {
    const handleSelectButton = (selectButton) => {
        props.handleSelectButton(selectButton);
    }

    const [open, setOpen] = useState(false);
    // const [page, setPage] = useState('');



    const toggleFunction = () => {
        setOpen((prevState) => !prevState);
        // setPage(page);
    }

    return (
    <>
        <div className="header-sp-fix">
            <header className="header-sp">
                <div className="header-sp-title">
                    <HashLink
                        to="/#top"
                        smooth={true}
                    >Kooh-Design</HashLink>
                </div>
                <ToggleButton
                    open={open}
                    controls="navigation"
                    label="メニューを開きます"
                    onClick={toggleFunction}
                />
                <Navigation
                    id="navigation"
                    open={open}
                    onClick={toggleFunction}
                    handleSelectButton={handleSelectButton}
                />
                <div className="video">
                    <video className="video-sp-item" src={video} autoPlay loop muted playsInline />
                </div>
            </header>
        </div>
    </>
    )
}

export default HeaderSp;
