import AboutContent from "./AboutContent";
import { mediaQuery, useMediaQuery } from "../../hooks/MediaQuery";
import './About.css';

function About() {
    const isPc = useMediaQuery(mediaQuery.pc);

    if(isPc){
        return (
            <>
                <div className="pc-body">
                    <AboutContent />
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="sp-body">
                    <AboutContent />
                </div>
            </>
        )
    }
}
export default About;