import './Sp-style.css';
import { Link } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';
// import { AnchorLink } from 'react-anchor-link-smooth-scroll';

const Navigation = (props) => {
    const id = props.id;
    const open = props.open;
    // const onClick = () => {
    //     props.onClick();
    // };
    // const handleSelectButton = (selectButton) => {
    //     props.handleSelectButton(selectButton);
    // }
    const handleNavClick = (selectButton) => {
        props.onClick();
        props.handleSelectButton(selectButton);

    }

    // useEffect(() => {
    //     const nav = document.getElementById(id);
    //     const links = nav.querySelectorAll("ul li a");
    //     if (open) {
    //         links.forEach(link => {
    //             link.style.opacity = "1"; // 要素を表示する場合はopacityを1に設定
    //         });
    //     } else {
    //         links.forEach(link => {
    //             link.style.opacity = "0"; // 要素を非表示にする場合はopacityを0に設定
    //         });
    //     }
    // }, [id, open]);

    return(
        <nav
            id={id}
            aria-hidden={!open}
            className="navigation"
        >
            <ul>
                <li className='nav'>
                    <Link
                        // to="/#about"
                        // smooth={true}
                        // className={( {isActive}) => (isActive ? 'active-menu' : undefined)}
                        onClick={() => handleNavClick("about")}
                    >
                    About</Link>
                </li>
                <li className='nav'>
                    <Link
                        // to="/#worktop"
                        // smooth={true}
                        // className={( {isActive}) => (isActive ? 'active-menu' : undefined)}
                        onClick={() => handleNavClick("work")}
                    >
                    Work</Link>
                </li>
                <li className='nav'>
                    <Link
                        // to="/#contact"
                        // smooth={true}
                        // className="class-page-contact"
                        // className={( {isActive}) => (isActive ? 'active-menu' : undefined)}
                        onClick={() => handleNavClick("contact")}
                    >
                    Contact</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation;