import { mediaQuery, useMediaQuery } from "../../hooks/MediaQuery";
import './Contact.css';
import ContactContent from "./ContactContent";

function Contact2() {
    const isPc = useMediaQuery(mediaQuery.pc);

    if(isPc){
        return (
            <>
                <div className="pc-body">
                    <ContactContent />
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="sp-body">
                    <ContactContent />
                </div>
            </>
        )
    }
}
export default Contact2;