import { useEffect, useState } from 'react'

export const mediaQuery = {
    sp: 'width < 420px',
    // tablet: '780px <= width < 1200px',
    pc: '420px <= width',
}

export const useMediaQuery = (query) => {
    const formattedQuery = `(${query})`
    const [match, setMatch] = useState(matchMedia(formattedQuery).matches)

    useEffect(() => {
        const mql = matchMedia(formattedQuery)

        if (mql.media === 'not all' || mql.media === 'invalid') {
            console.error(`useMediaQuery Error: Invalid media query`)
        }

        mql.onchange = (e) => {
            setMatch(e.matches)
        }

        return () => {
            mql.onchange = null
        }
    }, [formattedQuery, setMatch])

    return match
}