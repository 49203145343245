import { mediaQuery, useMediaQuery } from "./hooks/MediaQuery";

function Works() {
    const isPc = useMediaQuery(mediaQuery.pc);

    if(isPc){
        return (
            <>
                <div className="pc-body">
                    <h1>Works</h1>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="sp-body">
                    <h1>Works</h1>
                </div>
            </>
        )
    }
}
export default Works;