import './Contact.css';
import emailjs from '@emailjs/browser';
import { useState, useRef } from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import SendCompleteModal from './sendComplete/SendCompleteModal';

function ContactContent () {

    const refCaptcha = useRef();
    const [fromName, setFromName] = useState("");
    const [mail, setMail] = useState("");
    const [tel, setTel] = useState("");
    const [message, setMessage] = useState("");
    const [isOverlay, setIsOverlay] = useState("not-overlay");
    // Modal開閉用の変数
    const [isOpen, setIsOpen] = useState(false);

    const handleToTop = async() => {
        await setIsOpen(false);
        await setIsOverlay("not-overlay");
        window.location.reload();
        window.scrollTo(0,0);
    }


    function onChange(value){
        // console.log("Captcha value 2 :", value);
    }
    function onError(value){
        console.log("Error:", value);
    }
    

    const onClickSendMail = () => {
        const token = refCaptcha.current.getValue();
        // emailjsのUser_IDを使って初期化
        emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_ID);
        // 環境変数からService_IDとTemplate_IDを取得する。
        const emailjsServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const emailjsTemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        // emailjsのテンプレートに渡すパラメータを宣言
        const templateParams = {
            name: fromName,
            mail: mail,
            tel: tel,
            message: message,
            'g-recaptcha-response': token,
        };
        // ServiceId,Template_ID,テンプレートに渡すパラメータを引数にemailjsを呼び出し
        emailjs.send(   emailjsServiceId,
                        emailjsTemplateId,
                        templateParams
            )
            .then(()=>{
                console.log("mail sent");
                setIsOverlay("overlay")
                setIsOpen(true);
            });
    }


    return(
        <>
            <div id={isOverlay}>
                <SendCompleteModal isOpen={isOpen} handleToTop={handleToTop} />
            </div>
            <div className="content-title">
                <h1>・ Contact ・</h1>
            </div>
            <p className="contact-explain">
                以下のフォームにお問い合わせ内容をご記入のうえ、「送信」をクリックしてください。<br />
                <span>※ </span>の付いている項目は必須項目です。<br />
            </p>
            <form className="contact-form">
                <div className="text-areas">
                    <p>お名前<span> ※</span></p>
                    {/* <p className="alert-message">※お名前を入力してください</p> */}
                    <input className="input-area" value={fromName} onChange={(e) => setFromName(e.target.value)} name="name" />
                </div>
                <div className="text-areas">
                    <p>メールアドレス<span> ※</span></p>
                    {/* <p className="alert-message">※メールアドレスを入力してください</p> */}
                    <input className="input-area" value={mail} onChange={(e) => setMail(e.target.value)}  name="mail" />
                </div>
                <div className="text-areas">
                    <p>電話番号</p>
                    <input className="input-area" value={tel} onChange={(e) => setTel(e.target.value)}  name="tel" />
                </div>
                <div className="text-areas">
                    <p>お問い合わせ内容<span> ※</span></p>
                    {/* <p className="alert-message">※お問い合わせ内容を入力してください</p> */}
                    <textarea className="textarea-message" value={message} onChange={(e) => setMessage(e.target.value)}  name="message" />
                </div>
                <ReCAPTCHA ref={refCaptcha} onChange={onChange} sitekey="6LcNEq8pAAAAAN2kk0_hDRQU9RsIP90h1_q1KWcs" onErrored={onError} />
                <button onClick={onClickSendMail} className="submit-button" type="button" value="送信する">送信</button>
            </form>
        </>
    )
}
export default ContactContent;