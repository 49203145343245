import { useNavigate } from 'react-router-dom';
import './NotFound.css'
import { useEffect } from 'react';

const NotFound = () => {

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/');
        }, 5000);
        // eslint-disable-next-line
    }, [])

    return(
        <div>
            <div className="not-found">
                <h1>404 : Not Found</h1>
                <p>5秒後にTop画面に戻ります</p>
            </div>
        </div>
    )
}

export default NotFound;