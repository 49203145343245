import { mediaQuery, useMediaQuery } from "../../hooks/MediaQuery";
import HeaderImage from "../../img/header.jpg";
import "./Top.css";

const Top = () => {
    const isPc = useMediaQuery(mediaQuery.pc);

    if(isPc){
        return (
            <>
                <div className="top-body">
                    <img src={HeaderImage} alt="Header-Img" className="header-img"/>
                    <div  className="pc-body">
                    </div>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="top-body">
                    <img src={HeaderImage} alt="Header-Img" className="header-img"/>
                    <div  className="sp-body">
                    </div>
                </div>
            </>
        )
    }
}

export default Top;