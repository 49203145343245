
import "./SendCompleteModal.css";

const SendCompleteModal = (props) => {
    const isOpen = props.isOpen;
    const handleToTop = (isOpen) => {
        props.handleToTop(isOpen);
    }


    return(
        <>
            <dialog open={isOpen}>
                <div className="modal-content">
                    <p className="">送信を完了しました。</p>
                    <p className="">お問い合わせありがとうございます。</p>
                    <button className="modal-top-button" onClick={handleToTop}>Topへ戻る</button>
                </div>
            </dialog>
        </>
    )

    
}
export default SendCompleteModal;