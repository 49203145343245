import { Link } from 'react-router-dom';
import './WorkTop.css';

import Work1Image from "../../img/work-wedding-invitation.png";
import Work2Image from "../../img/work2.png";
import Work3Image from "../../img/work3.png";
import Work4Image from "../../img/work4.png";

function WorkTopContent() {


    return(
        <>
            <div className="worktop-title">
                <h1>・ Work ・</h1>
                <div className='work-links'>
                    <Link
                        className='work-link'
                        target="_blank"
                        to="https://wedding.kooh-web.com/"
                    >
                        <img src={Work1Image} alt="Work-Wedding-Invitation" className="work1-img" />
                        <div className='work-text'>
                            <h2>結婚式Web招待状</h2>
                            <p>人生で一度きりの結婚式！</p>
                            <p>ご要望がありましたら、</p>
                            <p>オリジナリティー溢れる招待状で</p>
                            <p>ゲストを招待してみませんか？</p>
                        </div>
                    </Link>
                    <Link
                        className='work-link'
                        target="_blank"
                        to="https://www.google.com/search?q=work2&sca_esv=10710e6d746a2a0b&sxsrf=ACQVn08WtF__3UBfVPlS-8KVAvFyGZwbUw%3A1714460065762&ei=oZUwZoeMLpDg2roP7K6GyAg&ved=0ahUKEwjH4M25remFAxUQsFYBHWyXAYkQ4dUDCBA&uact=5&oq=work2&gs_lp=Egxnd3Mtd2l6LXNlcnAiBXdvcmsyMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyCBAAGIAEGMsBMggQABiABBjLATIIEAAYgAQYywEyCBAAGIAEGMsBMggQABiABBjLAUjoCVC_BFiUBXABeAGQAQCYAYEBoAHqAaoBAzAuMrgBA8gBAPgBAZgCA6AC9AHCAgoQABiwAxjWBBhHwgIMECMYgAQYExgnGIoFwgILEAAYgAQYsQMYgwHCAggQABiABBixA5gDAIgGAZAGApIHAzEuMqAHlQY&sclient=gws-wiz-serp"
                    >
                        <img src={Work2Image} alt="Work2-Img" className="work2-img" />
                        <div className='work-text'>
                            <p>work2 text</p>
                        </div>
                    </Link>
                    <Link
                        className='work-link'
                        target="_blank"
                        to="https://www.google.com/search?q=work3&sca_esv=10710e6d746a2a0b&sxsrf=ACQVn09kyQ7WOQHTXnuKAcxd1ypWbWweEA%3A1714460091293&ei=u5UwZpu3EZvk2roPt-qs6AQ&ved=0ahUKEwjbgOTFremFAxUbslYBHTc1C00Q4dUDCBA&uact=5&oq=work3&gs_lp=Egxnd3Mtd2l6LXNlcnAiBXdvcmszMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBjLATIIEAAYgAQYywEyCBAAGIAEGMsBMggQABiABBjLATIKEAAYgAQYChjLATIKEAAYgAQYChjLATIIEAAYgAQYywFIiQ1QrwRY3AhwA3gBkAEAmAGzAaABpwKqAQMwLjK4AQPIAQD4AQGYAgWgArcCwgIKEAAYsAMY1gQYR8ICDBAjGIAEGBMYJxiKBcICChAAGIAEGEMYigXCAgsQABiABBixAxiDAcICCBAAGIAEGLEDmAMAiAYBkAYKkgcDMy4yoAf_BQ&sclient=gws-wiz-serp"
                    >
                        <img src={Work3Image} alt="Work3-Img" className="work3-img" />
                        <div className='work-text'>
                            <p>work3 text</p>
                        </div>
                    </Link>
                    <Link
                        className='work-link'
                        target="_blank"
                        to="https://www.google.com/search?q=work4&sca_esv=10710e6d746a2a0b&sxsrf=ACQVn09ULVxn_9JeN1PspfgvHRa3-vSL9Q%3A1714460150418&ei=9pUwZr-MGbjBvr0P67y04AU&ved=0ahUKEwi_3_zhremFAxW4oK8BHWseDVwQ4dUDCBA&uact=5&oq=work4&gs_lp=Egxnd3Mtd2l6LXNlcnAiBXdvcms0MgUQABiABDIIEAAYgAQYywEyChAAGIAEGAoYywEyCBAAGIAEGMsBMggQABiABBjLATIIEAAYgAQYywEyCBAAGIAEGMsBMggQABiABBjLATIIEAAYgAQYywEyCBAAGIAEGMsBSNQKUM8DWN8GcAF4AZABAJgBf6AB8AGqAQMwLjK4AQPIAQD4AQGYAgOgAvwBwgIKEAAYsAMY1gQYR8ICDBAjGIAEGBMYJxiKBcICChAjGIAEGCcYigXCAgsQABiABBixAxiDAZgDAIgGAZAGCpIHAzEuMqAHoQg&sclient=gws-wiz-serp"
                    >
                        <img src={Work4Image} alt="Work4-Img" className="work4-img" />
                        <div className='work-text'>
                            <p>work4 text</p>
                        </div>
                    </Link>
                </div>
            </div>
        </>

    )

}
export default WorkTopContent;