import HeaderPc from "./HeaderPc";
import HeaderSp from "./HeaderSp";
import { mediaQuery, useMediaQuery } from "../../hooks/MediaQuery";

const Header = (props) => {
    const isPc = useMediaQuery(mediaQuery.pc);

    const handleSelectButton = (selectButton) => {
        props.handleSelectButton(selectButton);
    }



    if(isPc){
        return (
            <HeaderPc 
            handleSelectButton={handleSelectButton}
            />
        )
    }else{
        return (
            <HeaderSp
            handleSelectButton={handleSelectButton}
            />
        )
    }
}

export default Header;
