import { mediaQuery, useMediaQuery } from "../../hooks/MediaQuery";
import FooterImage from "../../img/footer.jpg";

const Footer = () => {
    const isPc = useMediaQuery(mediaQuery.pc);

    if(isPc){
        return (
            <>
                <div className="footer-body">
                    <img src={FooterImage} alt="Footer-Img" className="footer-img"/>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="footer-body">
                    <img src={FooterImage} alt="Footer-Img" className="footer-img"/>
                </div>
            </>
        )
    }
}

export default Footer;