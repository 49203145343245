import WorkTopContent from "./WorkTopContent";
import { mediaQuery, useMediaQuery } from "../../hooks/MediaQuery";


function WorkTop() {
    const isPc = useMediaQuery(mediaQuery.pc);

    if(isPc){
        return (
            <>
                <div className="pc-body">
                    <WorkTopContent />
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="sp-body">
                    <WorkTopContent />
                </div>
            </>
        )
    }
}
export default WorkTop;