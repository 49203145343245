import Header from "./header/Header";
import Top from "./top/Top";
import About from "./about/About";
import WorkTop from "./worktop/WorkTop";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";
import { useRef } from "react";

const Layout = () => {

    const aboutRef = useRef(null);
    const worktopRef = useRef(null);
    const contactRef = useRef(null);

    // 要素を引数に渡したらその位置までスクロールする関数
    const scrollToElement = (ele) => {
        // スクロール先の要素の位置を取得
        const rect = ele.getBoundingClientRect();
        // スクロール先の要素の位置を調整
        const offset = 80;
        const top = rect.top + window.scrollY - offset;
        // スクロールの実行
        window.scrollTo({ top, behavior: 'smooth'});
    }


    // Navメニュークリック時に行う処理
    const handleSelectButton = async(selectButton) => {
        /// Topアイコンが押された場合
        if(selectButton === "top"){
            window.scrollTo({ top: 0, behavior: 'smooth'});
        }
        /// トップページでNavが押された場合
        /// スクロールを行う処理を発火
        if(selectButton === "about"){
            scrollToElement(aboutRef.current);
        }
        if(selectButton === "work"){
            scrollToElement(worktopRef.current);
        }
        if(selectButton === "contact"){
            scrollToElement(contactRef.current);
        }
    }

    return (
        <>
            <Header
                handleSelectButton={handleSelectButton}
            />
            <div id="top">
                <Top />
            </div>
            <div id="about" ref={aboutRef}>
                <About />
            </div>
            <div id="worktop" ref={worktopRef}>
                <WorkTop />
            </div>
            <div id="contact" ref={contactRef}>
                <Contact />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </>
    )
}
export default Layout;