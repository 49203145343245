import AboutImage from "../../img/about-bike.jpeg";
import './About.css';
function AboutContent() {

    return (
        <>
            <div className="about-title">
                <h1>・ About ・</h1>
            </div>
            <div className="about-content">
                <div className="about-img-area">
                    <div className="about-img">
                        <img src={AboutImage} alt="About-Img" className="about-image"/>
                    </div>
                </div>
                <div className="about-text">
                    <h3>Personal</h3>
                    <p>
                        ロードバイク<br />
                        ラグビー<br />
                        基本的に身体を動かすことが好きです。<br />
                        休日は大阪市内から六甲山頂までロードバイクでヒルクライムしています。
                    </p>
                    <h3>Education</h3>
                    <p>
                        京都大学大学院卒<br />
                        機械工学を専攻しており、主に力学について学びました。<br />
                        大学院ではプラズマ核融合の研究を行っていました。
                    </p>
                    <h3>Experience</h3>
                    <p>
                        JavaScriptを用いたフロントエンドの開発<br />
                        Javaを用いたバックエンドの開発<br />
                        VBAを用いたExcelツールの開発<br />
                        HTML/CSSを用いたWeb制作
                    </p>
                    <h3>Skills</h3>
                    <p>
                        JavaScript(React)<br />
                        Java(SpringBoot)<br />
                        VBA / HTML / CSS 等
                    </p>
                </div>
            </div>
        </>
    )
}
export default AboutContent;