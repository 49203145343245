import './Pc-style.css';
// import { NavLink } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';
// import { AnchorLink } from 'react-anchor-link-smooth-scroll';

import { Link } from "react-router-dom";
// import { Link } from "react-scroll";
import video from "../../img/bike.mp4";

const HeaderPc = (props) => {


    const handleSelectButton = (selectButton) => {
        props.handleSelectButton(selectButton);
    }



    return (
        <>
            <div className="header-pc-fix">
                <div className="header-pc">
                    <div className="header-pc-title">
                        <Link
                            onClick={() => handleSelectButton("top")}
                        >Kooh-Design</Link>
                    </div>
                    <div className="header-menu">
                        <ul className="list-menus">
                            <li className="list-menu">
                                <Link
                                    onClick={() => handleSelectButton("about")}
                                    // to="/"
                                >About</Link>
                            </li>
                            <li className="list-menu">
                                <Link
                                    onClick={() => handleSelectButton("work")}
                                    // to="/"
                                >Work</Link>
                            </li>
                            <li className="list-menu">
                                {/* <HashLink
                                    to="/#contact"
                                    smooth={true}
                                >Contact</HashLink> */}
                                <Link
                                    onClick={() => handleSelectButton("contact")}
                                    // to="/"
                                >Contact</Link>
                            </li>
                            {/* <li className="list-menu"><NavLink to="about">About</NavLink></li>
                            <li className="list-menu"><NavLink to="/work">Work</NavLink></li>
                            <li className="list-menu"><NavLink to="/contact">Contact</NavLink></li> */}
                        </ul>
                    </div>
                    <div className="video">
                        <video className='video-pc-item' src={video} autoPlay loop muted playsInline />
                    </div>  
                </div>
            </div>
        </>
    )
}

export default HeaderPc;
